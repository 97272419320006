<template>
    <div class="p-relative">
        <!-- Тулбар -->
        <div class="top-btns-bar">
            <div class="transparent-buttons">
                <v-btn small tile text @click="ExportList" :disabled="loading || total == 0">
                    <v-icon left>
                        far fa-file-chart-line
                    </v-icon>
                    {{ $t('Экспорт_списка') }}
                </v-btn>
                <v-btn v-if="allowImport" small tile text @click="ImportAR" :disabled="loading">
                    <v-icon left>
                        far fa-paper-plane
                    </v-icon>
                    {{ $t('Импорт_списка') }}
                </v-btn>
            </div>
            <!--Панель поиска-->
            <SearchPanel
                v-model="search"
                :has-filter="false"
            />
        </div>

        <!--Фильтр-->
        <v-form class="wrapperForm" ref="form" lazy-validation>
            <v-row dense>
                <v-col cols="12" sm="12" md="12" class="section-wrapper">
                    <v-card flat>
                        <v-card-text>
                            <div class="white-panel-items wpi-in-grids ma-0">
                                <div class="wp-row">
                                    <v-row class="one-column-field-in-one-box" no-gutters>
                                        <v-col cols="12" sm="12" md="2">
                                            <label class="f-label">{{$t("Территориальная_единица")}}</label>
                                        </v-col>
                                        <v-col  cols="12" sm="12" md="10">
                                            <v-select
                                                v-model="region"
                                                :items="filteredRegions"
                                                item-text="name"
                                                item-value="id"
                                                hide-details
                                                outlined
                                                dense
                                                append-icon="fas fa-chevron-down"
                                                class="autocomplete-chip ac-200 mt-1 mb-1 mr-1"
                                                :placeholder="$t('Территориальная_единица')"
                                                :menu-props="{ bottom: true, offsetY: true }"
                                                @change="regionChange"
                                                @click:clear="clearRegion"
                                                clearable
                                                v-tooltip.top-center='getToolTipRegion()'
                                                :readonly="filteredRegions.length == 1"
                                            >
                                                <template v-slot:prepend-item>
                                                    <v-list-item>
                                                        <v-list-item-content>
                                                            <v-text-field
                                                                :placeholder="$t('Поиск')"
                                                                class="mb-2 autocomplete-search"
                                                                hide-details
                                                                dense
                                                                clearable
                                                                autofocus
                                                                @input="searchAtcpList($event)"
                                                            >
                                                            </v-text-field>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </template>
                                            </v-select>
                                            <v-select 
                                                v-if="region != null && cities.length > 0"
                                                v-model="city"
                                                :items="filtredCities"
                                                item-text="name"
                                                item-value="id"
                                                hide-details
                                                outlined
                                                dense
                                                append-icon="fas fa-chevron-down"
                                                class="autocomplete-chip ac-200 ma-1"
                                                :placeholder="$t('Населенный_пункт')"
                                                :menu-props="{ bottom: true, offsetY: true }"
                                                @change="cityChange"
                                                @click:clear="clearCity"
                                                v-tooltip.top-center='city ? cities.find(r => r.id === city).name : ""'
                                                clearable
                                            >
                                                <template v-slot:prepend-item>
                                                    <v-list-item>
                                                        <v-list-item-content>
                                                            <v-text-field
                                                                :placeholder="$t('Поиск')"
                                                                class="mb-2 autocomplete-search"
                                                                hide-details
                                                                dense
                                                                clearable
                                                                autofocus
                                                                @input="searchCitiesList($event)"
                                                            >
                                                            </v-text-field>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </template>
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                </div>

                                <div v-if="visiblyStatusFilters" class="wp-row">
                                    <v-row class="one-column-field-in-one-box" no-gutters>
                                        <v-col cols="12" sm="12" md="2">
                                            <label class="f-label">{{$t("Статус")}}</label>
                                        </v-col>
                                        <v-col  cols="12" sm="12" md="10">
                                            <v-select
                                                v-model="selectedResidentsStatus"
                                                :items="residentsStatuses"
                                                item-text="name"
                                                item-value="value"
                                                hide-details
                                                outlined
                                                dense
                                                append-icon="fas fa-chevron-down"
                                                class="autocomplete-chip ac-200 mt-1 mb-1 mr-1"
                                                :menu-props="{ bottom: true, offsetY: true }"
                                            >
                                            </v-select>
                                            <v-select
                                                v-model="selectedElectionPartStatus"
                                                :items="electionPartStatuses"
                                                item-text="name"
                                                item-value="value"
                                                hide-details
                                                outlined
                                                dense
                                                append-icon="fas fa-chevron-down"
                                                class="autocomplete-chip ac-200 ma-1"
                                                :menu-props="{ bottom: true, offsetY: true }"
                                            >
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-form>

        <v-data-table
            class="elevation-1 customization-list-table pagination-list-table sticky-table"
            :headers="headers"
            :options.sync="options"
            :page.sync="options.page"
            :items="entities"
            :server-items-length="total"
            :footer-props="{ itemsPerPageOptions:[25, 50, 100] }"
            @page-count="paginationPageCount = $event"
            item-key="rka"
            @click:row="selectRow"
            :item-class="rowClass"
            :loading="loading"
        >

            <v-progress-linear slot="progress" height="2" color="#E43958" indeterminate></v-progress-linear>

            <template v-slot:[`item.city`]="{ item }">
                <div class="etw-text-container">
                    <div class="etw-text" v-tooltip.top-center="item.city">
                        {{ item.city }}
                    </div>
                </div>
            </template>

            <template v-slot:[`item.street`]="{ item }">
                <div class="etw-text-container">
                    <div class="etw-text" v-tooltip.top-center="getStreetHouse(item)">
                        {{ getStreetHouse(item) }}
                    </div>
                </div>
            </template>
            <template v-slot:[`item.electionPartNumber`]="{item}">
                <div class="d-flex align-center view-edit-table-click-box">
                    <div class="vetcb-text" v-tooltip.top-center='getElectionPartNameById(item.electionPartNumber)'>{{ item.electionPartNumber }}</div>
                    <v-select
                        :value="item.electionPartNumber"
                        :items="filteredSettingsParts"
                        item-text="id"
                        item-value="id"
                        hide-details
                        outlined
                        dense
                        append-icon=""
                        class="autocomplete-chip ac-100"
                        :placeholder="$t('Участок')"
                        :menu-props="{ bottom: true, offsetY: true }"
                        @change="partChange(item, $event)"
                    >
                        <template v-slot:prepend-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-text-field
                                        :placeholder="$t('Поиск')"
                                        class="mb-2 autocomplete-search"
                                        hide-details
                                        dense
                                        clearable
                                        autofocus
                                        @input="searchPartsList($event)"
                                    >
                                    </v-text-field>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-select>
                </div>
            </template>
            <template v-slot:[`item.residents`]="{ item }">
                <v-chip
                    v-if="item.residents > 0"
                    class="table-label"
                    small
                    label
                    dark
                    color="#43B581"
                    @click.stop="goToAddressSearch(item)"                    
                    v-tooltip.top-center="$t('Нажмите_для перехода_к_списку_лиц')"
                >
                    <v-icon>
                        fas fa-user
                    </v-icon>
                    <div>{{ item.residents }}</div>
                </v-chip>
                <v-chip
                    v-else
                    class="table-label"
                    small
                    label
                >
                    <v-icon>
                        fas fa-user
                    </v-icon>
                    <div>{{ item.residents }}</div>
                </v-chip>
            </template>
            <template v-slot:[`footer.prepend`]>
                <v-pagination
                    v-model="options.page"
                    :length="paginationPageCount"
                    :total-visible="$listPaginationTotal"
                    color="teal"
                ></v-pagination>
            </template>
        </v-data-table>

        <ImportAddressRegisterDlg ref="ImportAddressRegisterDlgRef" />
    </div>
</template>

<script>
import sys from '@/services/system';
import store from '@/store';
import i18n from '@/i18n';
import _ from 'lodash';
import { httpAPI, errorHandler } from "@/api/httpAPI";
import SearchPanel from '@/components/saylau/SearchPanel.vue';
import ImportAddressRegisterDlg from '@/components/saylau/dialogs/ImportAddressRegisterDlg.vue';

export default {
    name: "Addresses",
    components: {
        SearchPanel,
        ImportAddressRegisterDlg
    },
    computed: {
        headers() {
            let columns = [{ text: i18n.t("Код_АР"), value: 'rka', sortable: false, width: '130px' }];
            let canSortByStreet = true;
            if (this.cities.length > 0){
                columns.push({ text: i18n.t("Населенный_пункт"), value: 'city' });
                canSortByStreet = false;
            }
            columns.push(...[{ text: i18n.t("Улица_дом"), value: 'street', sortable: canSortByStreet },
                { text: i18n.t("Участок"), value: 'electionPartNumber', sortable: false, width: '100px' },
                { text: i18n.t("Количество_избирателей"), value: 'residents', width: '200px' },]);
            return columns;
        },
        region:  {
            get: function() {
                return this.$store.getters['saylau/lists/addresses/getRegionId'];
            },
            set: function(v) {
                this.$store.commit('saylau/lists/addresses/SET_TABLE_OPTION_PAGE', 1);
                this.$store.commit('saylau/lists/addresses/SET_REGION_ID', v);
            }
        },
        cities: {
            get: function() {
                return this.$store.getters['saylau/lists/addresses/getCities'];
            },
            set: function(v) {
                this.$store.commit('saylau/lists/addresses/SET_CITIES', v);
            }
        },
        parts: {
            get: function() {
                return this.$store.getters['saylau/lists/addresses/getElectionParts'];
            },
            set: function(v) {
                this.$store.commit('saylau/lists/addresses/SET_ELECTIONPARTS', v);
            }
        },
        filtredCities() {
            if (this.filtrStringCities) 
                return this.cities.map(x => x).filter(i => sys.checkSearch(i.name, this.filtrStringCities));
            else 
                return this.cities.map(x => x);
        },
        city: {
            get: function() {
                return this.$store.getters['saylau/lists/addresses/getCityId'];
            },
            set: function(v) {
                this.$store.commit('saylau/lists/addresses/SET_TABLE_OPTION_PAGE', 1);
                this.$store.commit('saylau/lists/addresses/SET_CITY_ID', v);
            }
        },
        selectedResidentsStatus:{
            get: function() {
                return this.$store.getters['saylau/lists/addresses/getWithResidents'];
            },
            set: function(v) {
                this.$store.commit('saylau/lists/addresses/SET_TABLE_OPTION_PAGE', 1);
                this.$store.commit('saylau/lists/addresses/SET_WITH_RESIDENTS', v);
            }
        },
        selectedElectionPartStatus: {
            get: function() {
                return this.$store.getters['saylau/lists/addresses/getWithElectionPart'];
            },
            set: function(v) {
                this.$store.commit('saylau/lists/addresses/SET_TABLE_OPTION_PAGE', 1);
                this.$store.commit('saylau/lists/addresses/SET_WITH_ELECTION_PART', v);
            }
        },
        options: {
            get: function() {
                return this.$store.getters['saylau/lists/addresses/getTableOptions'];
            },
            set: function(newValue) {
                if (newValue.sortBy[0] == "city" || newValue.sortBy[0] == "street"){
                    newValue.sortBy = ["city","street"];
                    let desc = newValue.sortDesc[0];
                    newValue.sortDesc = [desc, desc];
                }
                this.$store.commit('saylau/lists/addresses/SET_TABLE_OPTION', newValue);
            }
        },
        search: {
            get: function() {
                return this.$store.getters['saylau/lists/addresses/getSearch'];
            },
            set: _.debounce(function(newValue) {
                this.$store.commit('saylau/lists/addresses/SET_TABLE_OPTION_PAGE', 1);
                this.$store.commit('saylau/lists/addresses/SET_SEARCH', newValue);
            }, 300)
        },
        visiblyStatusFilters: {
            get: function() {
                return this.$store.getters['saylau/lists/addresses/getVisiblyStatusFilter'];
            },
            set: function(newValue) {
                this.$store.commit('saylau/lists/addresses/SET_VISIBLY_STATUS_FILTER',newValue);
            }
        },
        filter () {
            return {
                ...this.options,
                regionId:this.region,
                cityId:this.city,
                residentStatus: this.selectedResidentsStatus,
                electionPartStatus: this.selectedElectionPartStatus,
                filterString: this.search
            }
        },
        locale() {
            return this.$i18n.locale;
        },
        allowImport(){
            return true;// (this.$store.getters['global/auth/getUserInfo'])?.Permissions.includes("SaylauImportAR");
        }
    },
    data: () => {
        return {
            regions: [],
            filteredRegions: [],

            filtrStringCities: '',

            filteredSettingsParts: [],

            residentsStatuses: [
                {value: true, name: i18n.t('Есть_избиратели')},
                {value: false, name: i18n.t('Нет_избирателей')},
            ],
            electionPartStatuses: [
                {value: true, name: i18n.t('Прикреплен_к_участку')},
                {value: false, name: i18n.t('Не_прикреплен_к_участку')},
            ],

            paginationPageCount: 0,
            selectedRow: null,
            loading: false,
            total: 0,            
            entities: [],
        }
    },
    methods: {
        loadAddresses() {
            if (!this.region) {
                this.visiblyStatusFilters = false;
                return;
            }

            this.entities = [];
            this.total = 0;
            this.loading = true;

            httpAPI
                .get(`saylau/adressregister/list?filter=${this.getTableFilter()}`)
                .then(response =>
                {
                    this.entities = response.data.items;
                    this.total = response.data.count;
                    this.visiblyStatusFilters = true;
                })
                .finally(() => this.loading = false);
        },
        getTableFilter() {
            let { page, itemsPerPage, sortBy, sortDesc } = this.filter;
            let orderBy = sortBy.length <= 0 ? null : sortBy[0];
            let descending = sortDesc.length <= 0 ? null : sortDesc[0]; 
            let filter = {
                region: this.region,
                page,
                itemsPerPage,
                orderBy,
                descending,
                search: this.search,
                city: this.city,
                withelectionpart: this.selectedElectionPartStatus,
                withresidents: this.selectedResidentsStatus
            };
            return JSON.stringify(filter);
        },
        updateCities(){
            if (this.region){
                httpAPI
                    .get(`saylau/adressregister/cities/${this.region}`)
                    .then(response =>
                        {
                            this.cities = response.data;
                        })
                    .catch(() => {this.cities = [];});
            }
        },
        updateElectionParts(){
            if (this.region){
                httpAPI
                    .get(`saylau/election-parts/in-region/${this.region}`)
                    .then(response =>
                        {
                            this.parts = response.data;
                            this.filteredSettingsParts = this.parts.map(x => x);
                        })
                    .catch(() => {this.parts = [];});
            }
        },

        //#region Filter by Region
        regionChange(){
            this.clearRegion();
            this.updateCities();
            this.updateElectionParts();
        },
        searchAtcpList(searchStr) {
            if (searchStr) {
                this.filteredRegions = this.regions.map(x => x).filter(i => sys.checkSearch(i.name, searchStr));
            }
            else {
                this.filteredRegions = this.regions.map(x => x);
            }
        },
        clearRegion(){
            this.clearCity();
            this.cities = [];
        },
        //#endregion

        //#region Filter by City
        cityChange(){

        },
        searchCitiesList(searchStr) {
            this.filtrStringCities = searchStr;
        },
        clearCity(){
            this.city = null;
            this.search = '';
            this.entities = [];
            this.total = 0;
        },
        //#endregion
        
        //#region Setting ElectionPart in Table
        async partChange(item, partnumber){
            if (!item)
                return;
            try{
                let url = `saylau/adressregister/setelectionpart?rka=${item.rka}`;
                if (partnumber)
                    url += `&electionpart=${partnumber}`;
                else if (!item.electionPartNumber)
                    return;
                let result = await httpAPI.get(url);
                if (result.status === 204) {
                    item.electionPartNumber = partnumber;
                    this.$notify.success("Успешно_обновлено");
                }
                else {
                    this.$notify.alert(result.data);
                }
            }
            catch(ex){

                this.$notify.alert(ex.response.data);
            }   
        },
        searchPartsList(searchStr){
            if (searchStr) {
                this.filteredSettingsParts = this.parts.map(x => x).filter(i => (i.id).toString().includes(searchStr));
            }
            else {
                this.filteredSettingsParts = this.parts.map(x => x);
            }
        },
        //#endregion
        
        getStreetHouse(item){
            if (item.street)
                return `${this.firstCharToUpperCase(item.street)}, ${item.house}`;
            else 
                return item.house;
        },
        firstCharToUpperCase(str) {
            if (str){
                let firstChar = str.charAt(0);
                let upperFirstChar = firstChar.toUpperCase();
                let restOfString = str.slice(1);
                return upperFirstChar + restOfString;
            }
            return str;
        },
        selectRow(item) {
            this.selectedRow = item;
        },
        rowClass(item) {
            return {
                'selected-row': item === this.selectedRow
            };
        },
        getToolTipRegion(){
            if (this.region) 
                return this.regions.find(r => r.id === this.region)?.name;
            else 
                return '';
        },
        getElectionPartNameById(id){
            if (id && this.filteredSettingsParts.length > 0)
            {
                var electionArrById = this.filteredSettingsParts.find(r => r.id === id);
                return this.$i18n.locale == 'ru' ? electionArrById.nameRu : electionArrById.nameKz;
            } 
            else
            {
                return '';
            }
        },
        ExportList() {
            let onResolve = (response) => {
                let filename = "Export.xlsx";
                let headerLine = response.headers['content-disposition'];

                if (headerLine) {
                    let token = headerLine.split(';').map(t => t.trim()).find(t => t.startsWith("filename*=UTF-8"));

                    if (token)
                        filename = decodeURIComponent(token.replace("filename*=UTF-8''", ""));
                }

                this.$notify.success(`Загрузка файла "${filename}" завершена`);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
            };

            let onReject = (error) => {
                errorHandler(error);
            };

            let request = httpAPI.get(`saylau/adressregister/list?filter=${this.getTableFilter()}&export=true`, { responseType: 'blob', skipErrorHandler: true });
            this.$notify.async(request, onResolve, onReject, this.$t("Подготовка_данных"), { icons: { enabled: true } });
        },
        goToAddressSearch(item) {
            if (!this.selectedResidentsStatus)
                return;

            let filter = {
                region: this.region,
                city: item.cityId != null ? item.cityId : this.region,
                street: item.streetId,
                house: item.rka
            };

            this.$store.commit('saylau/lists/localSearch/SET_TABLE_OPTION_PAGE', 1);
            this.$store.commit('saylau/lists/localSearch/SET_SEARCH_TYPE', 2);
            this.$store.commit('saylau/lists/localSearch/SET_FILTER_ADDRESS', filter);
            this.$store.commit('saylau/lists/localSearch/SET_LOAD_WHEN_MOUNTED', true);

            this.$router.push({ name: "LocalSearch" });
        },
        async ImportAR(){
            try {
                let { regionId, filePO, fileVO } = await this.$refs.ImportAddressRegisterDlgRef.open(this.regions);

                let formData = new FormData();
                formData.append('files', filePO );
                formData.append('files', fileVO );
                let onResolve = (response) => {
                    this.$notify.success(response.data);
                };

                let onReject = (error) => {
                    errorHandler(error);
                };

                let request = httpAPI.post(`saylau/adressregister/updateadresses?regionGBDFLCode=${regionId}`, formData, { headers: {"Content-Type": "multipart/form-data"}, skipErrorHandler: true  });
                this.$notify.async(request, onResolve, onReject, this.$t("Импорт_данных"), { icons: { enabled: true } });
            }
            catch (ex){
                if (ex.message != "Cancelled")
                    this._vm.$notify.alert(ex.message);
            }
        }
    },
    watch: {
        filter: {
            handler () {
                this.loadAddresses()
            },
            deep: true,
        },
    },
    mounted() {
        if (this.regions.length == 1){
            this.region = this.regions[0].id;
        }
        this.regionChange();
    },
    async beforeRouteEnter(to, from, next) {
        let regions = await store.dispatch('saylau/references/fetchRegions');
        next(vm => { vm.regions = regions; vm.filteredRegions = regions; vm.searchCitiesList('')});
    },
    async beforeRouteUpdate(to, from, next) {
        this.regions = await store.dispatch('saylau/references/fetchRegions');
        this.filteredRegions = await store.dispatch('saylau/references/fetchRegions');
        next();
    }
}
</script>