<template>
    <v-dialog
        v-if="visible"
        v-model="visible"
        transition="dialog-top-transition"
        scrollable
        persistent
        max-width="700"
    >
        <v-card class="modal-maincard">

            <v-toolbar dark flat dense>
                <v-toolbar-title>{{ $t(title) }}</v-toolbar-title>
            </v-toolbar>

            <v-card-text class="wrapperFormModal">
                        
                <v-card
                    flat
                >
                    <v-card-text>

                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{ $t("Территориальная_единица") }}</label>
                            </v-col>
                            <v-col  cols="12" sm="12" md="8">
                                <!--<div class="onlyReadData"></div>-->
                                <v-autocomplete
                                    :items="regions"
                                    v-model="regionId"
                                    item-text="name"
                                    item-value="id"
                                    required
                                    outlined
                                    dense
                                    append-icon="fas fa-chevron-down"
                                    :readonly="regions.length == 1"
                                >
                                </v-autocomplete>
                            </v-col>
                        </v-row>

                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="3">
                                <label class="f-label">{{ $t("Первичные_адреса") }}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="9">
                                <div class="icon-click-row-group">

                                    <div @click="$refs.filePOSelect.click()">
                                        <input
                                            type="file"
                                            ref="filePOSelect"
                                            @change="onFilePOChange"
                                            style="display: none"
                                        />
                                        <v-icon small>
                                            fas fa-edit
                                        </v-icon>
                                    </div>
                                    
                                    <div class="cloud-file-list-box">
                                        <div class="cloud-items-container">

                                            <div v-if="!filePO" class="cfl-placeholder">{{ $t('Не_выбран_файл') }}</div>

                                            <v-row 
                                                v-else
                                                dense
                                            >
                                                <v-file-item
                                                    :name="filePO.name"
                                                    :size="filePO.size"
                                                    col-width="4"
                                                    :hide-load="true"
                                                    @on-delete-attachment="onDeletePO()"
                                                />
                                        </v-row>

                                        </div>
                                    </div>                
                                    

                                </div>
                            </v-col>
                        </v-row>

                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="3">
                                <label class="f-label">{{ $t("Вторичные_адреса") }}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="9">
                                <div class="icon-click-row-group">

                                    <div @click="$refs.fileVOSelect.click()">
                                        <input
                                            type="file"
                                            ref="fileVOSelect"
                                            @change="onFileVOChange"
                                            style="display: none"
                                        />
                                        <v-icon small>
                                            fas fa-edit
                                        </v-icon>
                                    </div>
                                    
                                    <div class="cloud-file-list-box">
                                        <div class="cloud-items-container">

                                            <div v-if="!fileVO" class="cfl-placeholder">{{ $t('Не_выбран_файл') }}</div>

                                            <v-row 
                                                v-else
                                                dense
                                            >
                                                <v-file-item
                                                    :name="fileVO.name"
                                                    :size="fileVO.size"
                                                    col-width="4"
                                                    :hide-load="true"
                                                    @on-delete-attachment="onDeleteVO()"
                                                />
                                        </v-row>

                                        </div>
                                    </div>                
                                    

                                </div>
                            </v-col>
                        </v-row>

                    </v-card-text>
                </v-card>

            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn 
                    color="cyan"
                    text
                    depressed
                    @click="ok"
                    v-if="isOkVisible"
                >
                    {{ $t("Загрузить") }} 
                </v-btn>

                <v-btn
                    color="blue-grey"
                    text
                    depressed
                    @click="cancel"
                >
                    {{ $t("Отмена") }}
                </v-btn>

            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>

export default {
    name: "ImportAddressRegisterDlg",
    data () {
        return {
            regions: [],
            title: 'Импорт_адресного_регистра',
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },
            visible: false,
            regionId: null,
            loading: false,
            filePO: null,
            fileVO: null,
            resolve: null,
            reject: null,
        }
    },
    computed: {
        isOkVisible() {
			return this.regionId != null && this.filePO != null && this.fileVO != null;			
        }
    },
    methods: {
        async open(regions){
            this.regionId = null;
            this.regions = regions;
            this.filePO = null;
            this.fileVO = null;
            this.visible = true;
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        async ok(){
            this.visible = false;
            this.resolve({ regionId: this.regionId, filePO: this.filePO, fileVO: this.fileVO });
        },
        async cancel(){
            this.visible = false;
            this.reject({ message: 'Cancelled' });
        },
        async onFilePOChange (event) {
            let files = Array.from(event.target.files);            
            if (files.length > 0){
                this.filePO = files[0];
                event.target.value = '';
            }
        },
        async onFileVOChange (event) {
            let files = Array.from(event.target.files);            
            if (files.length > 0){
                this.fileVO = files[0];
                event.target.value = '';
            }
        },
        onDeletePO() {
            this.filePO = null;
        },
        onDeleteVO() {
            this.fileVO = null;
        },
    }
}
</script>